
@tailwind base;
@tailwind components;
@tailwind utilities;

/*** Top Menu ***/

.bg-body-tertiary-gcMain {
    background-color: #333333;
    font-family: 'Inter', sans-serif;
    font-size: x-small;
    width: 100%;
    display: flex;
    flex-direction: column;
    
}
.myContainer{
    display: flex;
    width: 65%;
    height: 3.5vh;
    padding-left: 10%;
}

.myLogo{
    height: 3.5vh;
    width: 7vh;
    padding-right: 25%;
}
.navLinks{
    height: 2.5vh;
    width: 80%;

}
.me-auto{
    display: flex;
    flex-direction: column;
    font-size: larger;

}

#basic-navbar-nav{
    justify-content: left;
    padding-left: 25%;
}

/***** Footer *****/

.footerMain{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 2vh;
    background-color: #f5f5f7;
    height: auto;
    column-gap: 2vh;
    padding-top: 2%;    
}
.footerDetails{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2%;
    row-gap: 5%;
   
}
.footerContactLinks{
    
    width: 30vh;
    display: flex;
    flex-direction: column;
    row-gap: 1vh;
    column-gap: 1vh;
    background-color: #f5f5f7;
    height: auto;
   
    
}
.footerContacts{
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1vh;
    align-self: center;
    text-align: center;
   
}
.companyLinks{
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: 2vh;
    
}

.container-my-5-mainFooter{
    width: 100%;
}
.me-5{
    font-family: 'Inter', sans-serif;
    font-size: large;
    color: black;
    height: 1vh;
}
.socialAccs{
    display: flex;
    justify-content: space-evenly;
    ;
    font-family: 'Inter', sans-serif;
    font-size: x-large;
    height: 5vh;
}
.p-3{
    height: 5vh;
    padding: 2%;
}
.footerLogo{
    height: 30vh;
    width: 30%;
        
}
.footerLogoMoto{
    display: flex;
    justify-content: space-around;
    width: 50%;
    
}
.text-dark{
    text-decoration: none;
}

.footerLogoMotoSocial{
    height: 25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3vh;
    width: 100%;

}
.footerLogo{
    height: 10vh;
    width: 15vh;
}
.footerMoto{
    width: 50vh;
    text-align: center;
}
.footerSocial{
    width: 25vh;
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width: 700px) {
    .TopSub{
        width: 100%;
    }
    .TopLogo{
        margin-left: -5%;
    }
.footerMain{
    padding-top: 9%;
}
.footerDetails{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2%;
    width: 100%;
    column-gap: 2vh;
    row-gap: 5vh;
}
.footerContactLinks{   
    display: flex;
    flex-direction: column;
    width: 45%;
    row-gap: 1vh;
    column-gap: 1vh;
    background-color: #f5f5f7;
    height: auto;
    
}
.footerContacts{
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1vh;
    align-self: center;
    text-align: center;
    
}
.companyLinks{
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: 2vh;
    
}
.paraValues{
    width: 100%;
    height: 45vh;
    margin-top: 3%;
    padding: 0;
    background-position: center 65%;
    background-size: cover;
}
.paraVal{
    
    width: auto;
    height: auto;
    margin: auto;
    padding: 0%;
    margin-top: -3.5%;
}
.valDiv{
font-size: 5vh;
margin: auto;
}
.graycorpDiv{
font-size: medium;
margin-right: 30%;

}
.textDiv{
    font-size: x-small;
    margin: auto;
    margin-top: -5%;
}

.TechMain{
    height: 24vh;
  
   
}
.TechFlat{
    
    width: 87.5%;
    margin: 0%;
    left: 9.5%;
    

}
.TechRound{
   height: 15vh;
   bottom: 0%;
   left: -6%;
   width: 60%;
   border-radius: 0%;

}
.TechDesc{
    
    padding: 2%;
    height: 15.15vh;
    right: 5%;
    @apply w-[90%]
    
}
.TechDesc > h1{
    font-size: medium;
}
.TechDesc >p {
    font-size: x-small;
}

}
