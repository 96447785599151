.devMain{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    
}
.devPhoto{
    height: 95vh;
    width: 100%;
}
.devPhotoImage{
   height: 95vh;
   width: 100%;

}
.products{
     @apply flex justify-center items-center flex-wrap p-2 
     mob:gap-3 md:gap-2

}
.productTypes{
    font-family: 'Inter', sans-serif;
    @apply p-3 flex flex-col  text-center 
}
.ProIcon{
    @apply md:text-6xl mob:text-2xl
}
.ProName{
    @apply md:text-xl mob:text-sm
}
@media (max-width: 768px) {
    .GNL{
        @apply h-[5vh]
    }
    .divGNL{
        @apply gap-2 space-y-2
    }
   .logoTitle{
    @apply text-3xl
   }
   .paraValues{
    background: none;
    
   }
 
}
#Logos{
  
    @apply md:h-[12vh] md:w-[25vh] flex justify-end items-end
}
.divLogo{
    @apply flex justify-evenly flex-wrap gap-12 pt-5 
}
.card {
   @apply md:w-1/4 md:h-80 bg-gray-100 border-2 border-gray-800 
   shadow-lg backdrop-blur-md rounded-none text-center cursor-pointer 
   transition-all duration-500 flex items-center justify-center 
   select-none font-semibold text-black py-10 md:gap-y-10 mob:w-[30%] mob:h-36
     
  }
  
  .card:hover {
    border: 1px solid black;
    transform: scale(1.05);
  }
  
  .card:active {
    transform: scale(0.95) rotateZ(1.7deg);
  }

.companyNameAndDetails{
    @apply bg-[#f2f2f2] flex justify-center md:py-12   
}
.comNameAndDetailsSub{
    @apply md:w-10/12 mob:w-11/12 flex flex-col md:gap-10 
    mob:items-center justify-center  mob:gap-7 mob:pt-5
}
.slogan{
    @apply md:text-6xl md:font-bold text-[#333333] 
    flex flex-col items-center justify-center mob:text-2xl mob:font-extrabold mob:gap-2
}
.contactButton{
    @apply flex justify-center md:py-5 mob:px-2 mob:py-3 mob:w-full
}
.contactButton>a>button{
    @apply text-[#f2f2f2] bg-[#333333] h-full w-full text-base
}
.contactButton>a{
    @apply md:h-14 md:w-1/6 text-xl mob:h-10 mob:w-1/2 mob:px-5
}
.girlImg{
    @apply flex justify-center
}
.links{
    @apply md:font-semibold mob:gap-3 flex mob:flex-wrap 
    justify-between pt-3 mob:text-xs
}
#testId{
    border: 1px solid brown;
    height: 12vh;
}
.butt {
    --color: #999999;
    background-color: #333333;
    font-family: 'Inter', sans-serif;
    display: inline-block;
    width: 15vh;
    height: 7vh;
    line-height: 5vh;
    position: relative;
    overflow: hidden;
    border: 2px solid var(--color);
    transition: color .5s;
    z-index: 1;
    border-radius: 6px;
    font-size: 2.25vh;
    color: white;
   }
   
   .butt:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--color);
    height: 150px;
    width: 200px;
    border-radius: 50%;
   }
   
   .butt:hover {
    color: #101010;
   }
   
   .butt:before {
    top: 100%;
    left: 100%;
    transition: all .7s;
   }
   
   .butt:hover:before {
    top: -30px;
    left: -30px;
   }
   
   .butt:active:before {
    background: #999999;
    transition: background 0s;
   }
#myBtn{
    background-color: #999999;
    border-color: #999999;
    color: #efecec;
    --color: #080808;

}
#myBtn :hover{
    color: #333333;
}

.imgSlideDiv{
    padding-top: 3%;
    padding-left: 10%;
}
.imgSlideImage{
   
}
.imgSlider{
    height:30vh;
    padding:1%;
    width:98%;
    display: flex;
    justify-content: space-around;
    column-gap: 1%;

    
}
@media screen and (max-width: 760px) {
    .devMain>div>h2{
            font-size: large;
    }
.comNam{
    font-size: 8vh;
    height: auto;
    width: auto;
    font-weight: 600;
} 
.companyName{
    height: auto;
}
.slot{
    font-size: 3.5vh;
    height: 0.5vh;
}
.slot-details{
    font-size: 1.5vh;
    color: #999999;
    padding: 0%;
    
} 
#slotButt{
    padding-top: 5.5%;
}
.comMotoPart{
    display: flex;
    row-gap: 0.5vh;
    height: 4vh;
}
.comMotoPart>div{
    width: auto;
    display: flex;
    height: auto;
} 
.unleash{
    font-size: 3.5vh;
    height: 10vh;
}
.btn{
    font-size: x-small;
    width: 90%;
}
.companyNameAndDetails{
    height: 40vh;
    
}
.comMoto{
    height: 23vh;
}
.devPhotoImage{
    height: auto;
}
.imgSlideImage{
height: 10vh;
}
.imgSlider{
    height: 15vh;
  
    padding: 0;
}
.devPhoto{
    height: auto;
}
.imgSlideDiv{
    padding-top: 0%;
    margin-top: -5%;
   
}

}