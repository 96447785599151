.nvmMain{

    font-family: "Inter", sans-serif;

@apply bg-[#666666] md:pl-10 
flex justify-center items-center gap-3 py-14

}
.nvmMain> div{
    background-color: #f5f5f7;
    
}
.names{
    width: 31%;
  
}
.vision{
    
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #333333;
    width: 45%;
   
}
.vision>div{
    height: 20.75vh;
    font-family: "Inter", sans-serif;
    display: flex;
    flex-direction: column;
    row-gap: 1vh;
    justify-content: center;
    text-align: center;
}
.details{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    
}

.details>div{
    @apply flex md:flex-wrap md:w-9/12 text-justify h-1/2
    mob:flex-col
}
.details>div>div{ 
@apply md:w-6/12 h-1/2 pl-10 pr-10 pt-5 flex flex-col gap-5
}
.detailsHeadSubHead>span>h1{
    @apply font-[inter] font-semibold md:text-lg
    mob:text-sm text-[#333333]
}
.detailsHeadSubHead>span>h3{
  @apply text-sm text-[#666666]
}
.detailsHeadSubHead{
  @apply flex justify-between
}
.details>div>div>p{
  @apply font-[inter] font-normal md:text-sm
  mob:text-xs text-[#999999]
}

@media screen and (max-width: 760px) {
  .CompanyPhoto{
    height: 35vh;
  }
  .CompanyPhoto>img{
    height: 90%;
    width: 90%;
  }
  .details > div > h1 {
      font-size: medium; /* Adjust the font size for mobile view */
    }
    .tests{
        
        font-size: small;
    }
    .nvmMain{
      @apply flex-col gap-3 py-5
    }
    .names{
      @apply w-full
    }
    .vision{
      @apply w-10/12
    }
  }
  .WhyWeMain{
    
    padding: 1%;
    display: flex;
    flex-direction: column;
    row-gap: 1vh;
    justify-content: center;
    align-items: center;
  }
  #WhyWeCaption{
    height: 10vh;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 7vh;
    margin-left: -5%;
  }
  
  .WhyWeMain>div{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
   
    height: 75vh;
    width: 70%;   
    padding: 1%;
   

  }
  .WhyWeMain>div>div{
   
    width: 30%;
    height: 70vh;
    padding: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1vh;
   
  }
  .WhyWeMain>div>div>div{
 
    height: 35vh;
    width: 100%;
    padding: 1%;
  }
  #WhyWeLogo{
   width: 45%;
   height: 15vh;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   padding: 15%;
   font-size: 5vh;
   color: #333333;
   border-color: #333333;
   background-color: #999999;
   margin-left: -35%;
   
  }
  #WhyWeHead{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 15vh;
    padding: 2%;
    font-family: "Inter", sans-serif;
  }
  #WhyWeHead>h1{
    font-size: xx-large;
    font-weight: 500;
  }
  #WhyWeHead>h3{
    font-size: x-large;
    font-weight: 500;
  }
  #WhyWeOl{
    padding-top: 10%;
  }
  #WhyWeOl>ol{
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
    justify-content: space-between;
  }
  #WhyWeOl>ol>li{
    font-size: large;
    font-weight: 500;
  }
 
    
@media screen and (max-width: 1200px) {

  #WhyWeLogo{
    height: 12vh;
    font-size: 4vh;
   }
   #WhyWeHead>h1{
    font-size: x-large;
    font-weight: 500;

  }
  #WhyWeHead>h3{
    font-size: large;
    font-weight: 500;
  }
  #WhyWeOl>ol>li{
    font-size: large;
  
  }

}
   
@media screen and (max-width: 1000px) {

  #WhyWeLogo{
    height: 5vh;
    font-size: 2vh;
   }
   #WhyWeHead>h1{
    font-size: small;
    
  }
  #WhyWeHead>h3{
    font-size: x-small;
   
  }
  #WhyWeOl>ol>li{
    font-size: x-small;
    
  }
  .WhyWeMain>div{
    width: 75%;
    display: flex;
    height: 50vh;
  }
  .WhyWeMain>div>div{
   
    width: 32%;
    height: 70vh;
    padding: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1vh;
   
  }
  .backimgapple{
    width: 100%;
    height: 10vh;
  }
 
  .names,.vision{
    height: 25vh;
  }
  
}

.whuUsContainer{
  @apply md:w-1/3 mob:w-full flex mob:flex-wrap justify-center items-end bg-[#333333]
}

.whyUsDescDiv{
  @apply bg-[#333333] md:h-52 mob:h-40 w-full z-0 flex
  flex-col md:gap-5 mob:gap-2 justify-center 
  text-[#f5f5f7] font-medium text-center items-center

}
.whyUsDescDiv>h1{
@apply md:text-3xl mob:text-lg text-[#f5f5f7]
}
.whyUsDescDiv>ol{
@apply flex flex-col md:text-sm mob:text-xs font-normal
}
